import * as React from "react";
import styled from "styled-components";

import { colors } from "./tokens";

const ALLOWED_NON_NUMERICAL_MARGIN_VALUES = ["initial", "auto"];

export type BoxPropTypes = {
  p?: (string | number)[];
  m?: (string | number)[];
  [other: string]: any;
};

// eslint-disable-next-line no-undef
export type BoxProps = React.HTMLAttributes<HTMLDivElement> & BoxPropTypes;
export type sizeType = number | string | null | undefined;

const borderStyle = {
  DOTTED: "dotted",
  DASHED: "dashed",
  SOLID: "solid",
  DOUBLE: "double",
  GROOVE: "groove",
  RIDGE: "ridge",
  INSET: "inset",
  OUTSET: "outset",
  NONE: "none",
  HIDDEN: "hidden",
} as const;

const formatRemValue = (val: sizeType) => (val?.toString().includes("rem") ? val : `${val}rem`);

function getRemCssValue(value: BoxProps["m"] | BoxProps["p"]): string | null {
  if (value && value.length) {
    return value
      .map((val: sizeType) => {
        if (val && ALLOWED_NON_NUMERICAL_MARGIN_VALUES.includes(val.toString())) {
          return val;
        }

        return formatRemValue(val);
      })
      .join(" ");
  }
  return null;
}

const StyledBox = styled.div<BoxProps>`
  cursor: ${(props) => (props.onClick ? "pointer" : undefined)};
  box-sizing: border-box;
  margin: ${(props) => getRemCssValue(props.m)};
  padding: ${(props) => getRemCssValue(props.p)};
  display: ${(props) => (props.justifyContent || props.alignItems || props.flexDirection ? "flex" : undefined)};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.flexDirection};
  border: ${(props) =>
    props.borderColor || props.borderSize || props.borderStyle
      ? `${props.borderSize || 1}px ${props.borderStyle || borderStyle.SOLID} ${
          props.borderColor || colors.content.neutral.n10
        }`
      : undefined};
  border-radius: ${(props) => (props.borderRadius ? formatRemValue(props.borderRadius) : undefined)};
  overflow: ${(props) => props.overflow};
  width: ${(props) => (props.fullWidth ? "100%" : undefined)};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : undefined)};
`;

export const Box = ({ m = [], p = [], children, ...otherProps }: BoxProps): React.ReactElement => {
  return (
    <StyledBox m={m} p={p} {...otherProps}>
      {children}
    </StyledBox>
  );
};

export default Box;
