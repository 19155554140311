import React from "react";
import PropTypes from "prop-types";

import { Box, tokens } from "./UI";

const Card = ({ children, backgroundColor, borderColor }) => (
  <Box
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    borderRadius={tokens.spacing.s4}
    m={[0, 0, tokens.spacing.s4, 0]}
  >
    <Box p={[tokens.spacing.s4]}>{children}</Box>
  </Box>
);

Card.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
};

Card.defaultProps = {
  children: null,
  backgroundColor: undefined,
  borderColor: undefined,
};

export default Card;
