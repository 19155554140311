import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Button, Box, tokens } from "../UI";
import { Bold, StepCard } from "../../components";
import { getUnitoFields, populateUnitoFields, saveWorkbook, UnitoField } from "../../../utils";

const Success = require("./../../../../assets/success.svg").default as string;

const Alert = styled(Box)`
  border-radius: 0.25rem;
  background-color: ${tokens.colors.background.message.positive};
  border: 1px solid ${tokens.colors.strokes.message.positive};
`;

const StyledSuccess = styled(Success)`
  width: 12px;
`;

const StepOne = ({ setIsConfigured, isConfigured }) => {
  const [configuring, setConfiguring] = useState(false);

  async function handleClick() {
    setConfiguring(true);
    try {
      await Excel.run(async (context: Excel.RequestContext) => {
        const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();

        const usedRange = activeWorksheet.getUsedRange(true);

        const { [UnitoField.UNITO_ID]: unitoIdData, [UnitoField.LAST_MODIFIED]: lastModifiedData } =
          await getUnitoFields(context, usedRange);

        if (!unitoIdData.isSet) {
          activeWorksheet
            .getRange("A:A")
            .insert("Right")
            .set({ format: { fill: { color: "#83D3D1" } } });

          activeWorksheet.getCell(0, 0).values = [["UnitoID"]];
        }

        if (!lastModifiedData.isSet) {
          const newColumn = usedRange.getLastColumn().getColumnsAfter().getEntireColumn();

          newColumn.set({ format: { fill: { color: "#83D3D1" } } });

          newColumn.getCell(0, 0).values = [["Last modified"]];
        }

        /* eslint-disable office-addins/no-empty-load */
        usedRange.load();

        await context.sync();
        await saveWorkbook(context);
        await populateUnitoFields(context, usedRange);

        await context.sync();
        await setIsConfigured(context, true);
      });
    } catch (error) {
      console.error(error);
    }
    setConfiguring(false);
  }

  return (
    <StepCard
      buttonCTA={
        !isConfigured ? (
          <Button onClick={handleClick} disabled={configuring}>
            Insert the two columns in this sheet
          </Button>
        ) : (
          <Alert p={[0.5, 1]} justifyContent="center">
            <Box m={[0, 0.5, 0, 0]}>
              <StyledSuccess />
            </Box>
            Columns inserted successfully
          </Alert>
        )
      }
    >
      You’ll need two extra columns: <Bold>UnitoID</Bold> at the beginning and <Bold>Last Modified</Bold> at end of your
      sheet, that will be filled while your flow syncs. Please do not delete or edit either of these columns as it will
      cause issues with the sync.
    </StepCard>
  );
};

StepOne.propTypes = {
  setIsConfigured: PropTypes.func.isRequired,
  isConfigured: PropTypes.bool.isRequired,
};

export default StepOne;
