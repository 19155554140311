import React from "react";
import styled from "styled-components";

import { tokens, Box } from "../UI";

import { Bold, StepCard } from "../../components";

const Plus = require("./../../../../assets/plus.svg").default as string;

const ButtonLink = styled.a`
  color: ${tokens.colors.global.primary.light};
  display: flex;
  text-decoration: none;
  border-radius: 0.25rem;
  background-color: ${tokens.colors.content.secondary.default};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${tokens.colors.content.secondary.hover};
  }
`;

const StyledPlus = styled(Plus)`
  width: 12px;
`;

const StepThree = () => {
  return (
    <StepCard
      buttonCTA={
        <ButtonLink
          variant="secondary"
          type="href"
          target="_blank"
          href="https://app.unito.io/#/dashboard/flow-builder/add"
        >
          <Box justifyContent="center" p={[0.5, 1]}>
            <Box m={[0, 0.5, 0, 0]} justifyContent="center">
              <StyledPlus />
            </Box>
            Create a new flow
          </Box>
        </ButtonLink>
      }
    >
      If you've already started building your flow, you can go back and finalize your setup. If not, click the button
      below to <Bold>start building a new flow</Bold> in 5 easy steps.
    </StepCard>
  );
};

export default StepThree;
