import React from "react";

import { Bold, StepCard } from "../../components";

const StepTwo = () => {
  return (
    <StepCard>
      Use <Bold>distinct and descriptive names</Bold> for the columns between <Bold>UnitoID</Bold> and{" "}
      <Bold>Last Modified</Bold> in the first row, which you'll use to map to fields in other tools. You should freeze
      the first row via <Bold>View {">"} Freeze Panes</Bold> so it doesn't move and cause issues with the sync.
    </StepCard>
  );
};

export default StepTwo;
