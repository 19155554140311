import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Box, tokens } from "../UI";

import { Card } from "../../components";

const Typography = styled.p`
  font-size: ${tokens.fontSize.f7};
  font-weight: ${tokens.fontWeight.fw4};
  line-height: ${tokens.lineHeight.lh4};
  color: ${tokens.colors.content.neutral.n30};
`;

const StepCard = ({ children, buttonCTA }) => {
  return (
    <Card backgroundColor={tokens.colors.global.primary.light} borderColor={tokens.colors.content.neutral.n10}>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <Typography>{children}</Typography>
      </Box>
      <Box justifyContent="center">{buttonCTA || null}</Box>
    </Card>
  );
};

StepCard.propTypes = {
  children: PropTypes.node,
  buttonCTA: PropTypes.node,
};

StepCard.defaultProps = {
  children: null,
  buttonCTA: null,
};

export default StepCard;
