import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { tokens, Box } from "../UI";

const StyledButton = styled.div`
  cursor: pointer;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  transition: all 0.2s;
  justify-content: center;
  position: relative;
  background: ${tokens.colors.content.primary.default};
  color: ${tokens.colors.global.primary.light};
  background: ${(props: any) =>
    props.disabled || props.$isLoading
      ? tokens.colors.content.primary.disabled
      : tokens.colors.content.primary.default};
  border: ${(props: any) =>
    props.disabled || props.$isLoading
      ? tokens.colors.content.primary.disabled
      : tokens.colors.content.primary.default};

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      color: ${tokens.colors.global.primary.light};
      background: ${tokens.colors.content.primary.hover};
      text-decoration: none;
      cursor: pointer;
    }

    &:focus {
      outline-offset: 2px;
      outline-color: ${tokens.colors.content.primary.disabled};
      cursor: pointer;
    }
  }
`;

export const Button = ({ onClick, disabled, children }) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      <Box p={[0.5, 1]}>{children}</Box>
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  backgroundColor: undefined,
  borderColor: undefined,
};

export default Button;
