type Nullable<T> = { [K in keyof T]: null };

export enum UnitoField {
  UNITO_ID = "UnitoID",
  LAST_MODIFIED = "Last modified",
}

interface ColumnPosition {
  alphaPosition: string;
  index: number;
}

interface SetColumn extends ColumnPosition {
  isSet: true;
}

interface UnsetColumn extends Nullable<ColumnPosition> {
  isSet: false;
}

export type ColumnData = SetColumn | UnsetColumn;

export type UnitoFieldsData = {
  [key in UnitoField]: ColumnData;
};

export interface CellCoordinate {
  column: number | string;
  row: `${number}`;
}

export enum Properties {
  IS_UNITO_CONFIGURED = "isUnitoConfigured",
  IS_UNITO_INSTALLED = "isUnitoInstalled",
}

export type Property = [Properties, boolean];
