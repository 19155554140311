import React from "react";
import styled from "styled-components";

const Chevron = require("./../../../assets/chevron.svg").default as string;

import { Box, tokens } from "./UI";
import { Bold, Card } from "../components";

const Typography = styled.p`
  font-size: ${tokens.fontSize.f7};
  font-weight: ${tokens.fontWeight.fw4};
  line-height: ${tokens.lineHeight.lh4};
  color: ${tokens.colors.content.neutral.n40};
`;

const StyledLogo = styled.img`
  width: 102px;
`;

const IconContainer = styled(Box)`
  display: inline;
`;

const StyledChevron = styled(Chevron)`
  width: 10px;
`;

const Header = () => {
  return (
    <>
      <Box justifyContent="center" m={[0, 0, tokens.spacing.s4, 0]}>
        <StyledLogo src={require("./../../../assets/unito_wordmark@12x.png")} alt="Unito Logo" title="Unito Logo" />
      </Box>
      <Card backgroundColor={tokens.colors.background.message.info}>
        <Typography>
          <Bold>Keep this add-in window open</Bold> or <Bold>minimize it</Bold> with the
          <IconContainer p={[tokens.spacing.s3]}>
            <StyledChevron />
          </IconContainer>
          while you're working on this sheet in order for Unito to track changes. You may need to refresh this page to
          see information that has synced.
        </Typography>
      </Card>
    </>
  );
};

export default Header;
