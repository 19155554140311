export const colors = {
  global: {
    primary: {
      light: "#ffffff",
      dark: "#192230",
    },
  },
  background: {
    primary: {
      action: "#e8f6fb",
    },
    neutral: {
      grey: "#f3f6f8",
    },
    message: {
      info: "#eaf2ff",
      positive: "#e7f9ef",
      warning: "#fff4e7",
      destructive: "#ffeef1",
    },
    branding: {
      unito: "#e9f7f7",
      mirror: "#efeeff",
    },
  },
  strokes: {
    message: {
      info: "#7eb0fc",
      positive: "#81daa7",
      warning: "#ffbf74",
      destructive: "#f17287",
    },
    branding: {
      unito: "#83d3d1",
      mirror: "#9d99dd",
    },
  },
  content: {
    primary: {
      default: "#187d9d",
      hover: "#004263",
      disabled: "#bde2ed",
    },
    secondary: {
      default: "#152a4c",
      hover: "#03132d",
      disabled: "#d7dde8",
    },
    neutral: {
      n40: "#02060f",
      n30: "#677888",
      n20: "#9cabb9",
      n10: "#d7dde8",
    },
    info: {
      default: "#2577f1",
      hover: "#0f5ed5",
      disabled: "#bad3f7",
    },
    positive: {
      default: "#3abf72",
      hover: "#109146",
      disabled: "#95deb4",
    },
    warning: {
      default: "#fe9012",
      hover: "#dc7806",
      disabled: "#f8d6af",
    },
    destructive: {
      default: "#e22644",
      hover: "#c40003",
      disabled: "#ffdde3",
    },
    branding: {
      unito: "#3aafa9",
      mirror: "#5f5bba",
    },
  },
};

export const spacing = {
  /** 0rem */
  s0: "0rem",
  /** 0.125rem */
  s1: "0.125rem",
  /** 0.25rem */
  s2: "0.25rem",
  /** 0.5rem */
  s3: "0.5rem",
  /** 1rem */
  s4: "1rem",
  /** 1.5rem */
  s5: "1.5rem",
  /** 2rem */
  s6: "2rem",
  /** 3rem */
  s7: "3rem",
  /** 4rem */
  s8: "4rem",
  /** 8rem */
  s9: "8rem",
};

export const fontSize: { [size: string]: string } = {
  /** 2.5rem */
  f1: "2.5rem",
  /** 2rem */
  f2: "2rem",
  /** 1.5rem */
  f3: "1.5rem",
  /** 1.25rem */
  f4: "1.25rem",
  /** 1.125rem */
  f5: "1.125rem",
  /** 1rem */
  f6: "1rem",
  /** 0.875rem */
  f7: "0.875rem",
  /** 0.75rem */
  f8: "0.75rem",
};

export const lineHeight: { [height: string]: string } = {
  /** 3rem */
  lh1: "3rem",
  /** 2.5rem */
  lh2: "2.5rem",
  /** 2rem */
  lh3: "2rem",
  /** 1.5rem */
  lh4: "1.5rem",
  /** 1rem */
  lh5: "1rem",
};

export const fontWeight: { [weight: string]: number } = {
  /** 300 */
  fw3: 300,
  /** 400 */
  fw4: 400,
  /** 700 */
  fw7: 700,
  /** 900 */
  fw9: 900,
};
