import * as React from "react";
import styled from "styled-components";

import { Box, tokens } from "./UI";

import { Header, Loading, StepOne, StepTwo, StepThree } from "../components";
import { getUnitoFields, populateUnitoFields, Properties, Property, UnitoField } from "../../utils/";

const Typography = styled.h4`
  font-size: ${tokens.fontSize.f6};
  font-weight: ${tokens.fontWeight.fw7};
  line-height: ${tokens.lineHeight.lh3};
  color: ${tokens.colors.content.neutral.n40};
`;

const StyledLogo = styled.img`
  width: 102px;
`;

export interface AppProps {
  isOfficeInitialized: boolean;
}

export interface AppState {
  isConfigured: boolean;
  isInstalled: boolean;
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context: Excel.RequestContext) {
    super(props, context);
    this.state = {
      isConfigured: false,
      isInstalled: false,
    };
  }

  async saveIsConfiguredProperty(context: Excel.RequestContext, property: Property) {
    try {
      const [key, value] = property;
      let worksheet = context.workbook.worksheets.getActiveWorksheet();
      worksheet.load(["id"]);
      await context.sync();

      let customDocProperties = context.workbook.properties.custom;
      customDocProperties.add(`${key}-${worksheet.id}`, `${value}`);
      await context.sync();
    } catch (error) {
      console.error(error);
    }
  }

  async getIsConfiguredProperty(context: Excel.RequestContext, property: Properties) {
    try {
      let worksheet = context.workbook.worksheets.getActiveWorksheet();
      worksheet.load(["id"]);
      await context.sync();

      let customDocProperties = context.workbook.properties.custom;
      let customProperty = customDocProperties.getItemOrNullObject(`${property}-${worksheet.id}`);

      /* eslint-disable office-addins/no-navigational-load */
      customProperty.load(["key", "value", "isNullObject"]);

      await context.sync();

      if (customProperty.isNullObject) {
        return false;
      }
      return customProperty.value === "true";
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async saveIsIntalledProperty(context: Excel.RequestContext, property: Property) {
    try {
      const [key, value] = property;
      let customDocProperties = context.workbook.properties.custom;
      customDocProperties.add(key, `${value}`);
      await context.sync();
    } catch (error) {
      console.error(error);
    }
  }

  async getIsInstalledProperty(context: Excel.RequestContext, property: Properties) {
    try {
      let customDocProperties = context.workbook.properties.custom;
      let customProperty = customDocProperties.getItemOrNullObject(property);
      customProperty.load(["key", "value", "isNullObject"]);
      await context.sync();

      if (customProperty.isNullObject) {
        return false;
      }

      return customProperty.value === "true";
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async checkUnitoFields(context: Excel.RequestContext, activeWorksheet: Excel.Worksheet) {
    const usedRange = activeWorksheet.getUsedRange();

    const { [UnitoField.UNITO_ID]: unitoIdData, [UnitoField.LAST_MODIFIED]: lastModifiedData } = await getUnitoFields(
      context,
      usedRange,
    );

    await this.setIsConfigured(context, unitoIdData.isSet && lastModifiedData.isSet);
  }

  async componentDidMount() {
    // https://docs.microsoft.com/en-us/office/dev/add-ins/develop/automatically-open-a-task-pane-with-a-document
    Office.context.document.settings.set("Office.AutoShowTaskpaneWithDocument", true);
    Office.context.document.settings.saveAsync();

    try {
      await Excel.run(async (context: Excel.RequestContext) => {
        const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
        activeWorksheet.onChanged.add(this.handleOnCellChange);
        activeWorksheet.onDeactivated.add(this.handleActiveWorksheetChange);
        await context.sync();

        // Every time the addon is mounted, we validate that the spreadsheet contains the Unito columns,
        // instead of relying on the IS_UNITO_CONFIGURED property.
        //
        // By doing so, we can catch the edge case where the user closes the addon panel and removes a Unito column.
        this.checkUnitoFields(context, activeWorksheet);

        const isInstalled = await this.getIsInstalledProperty(context, Properties.IS_UNITO_INSTALLED);
        const isConfigured = await this.getIsConfiguredProperty(context, Properties.IS_UNITO_CONFIGURED);

        this.setState({
          isInstalled,
          isConfigured,
        });

        if (!isInstalled) {
          await this.saveIsIntalledProperty(context, [Properties.IS_UNITO_INSTALLED, this.state.isInstalled]);
          this.setState({ isInstalled: true });
        }

        if (isConfigured) {
          const usedRange = activeWorksheet.getUsedRange(true);

          /* eslint-disable office-addins/no-empty-load */
          usedRange.load();

          await context.sync();
          await populateUnitoFields(context, usedRange, true);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  setIsConfigured = async (context: Excel.RequestContext, isConfigured: boolean) => {
    this.setState({ isConfigured });
    await this.saveIsConfiguredProperty(context, [Properties.IS_UNITO_CONFIGURED, isConfigured]);
  };

  handleOnCellChange = async (changes: Excel.WorksheetChangedEventArgs) => {
    await Excel.run({ delayForCellEdit: true }, async (context: Excel.RequestContext) => {
      try {
        if (changes.triggerSource === Excel.EventTriggerSource.thisLocalAddin) {
          return;
        }

        if (changes.changeType === Excel.DataChangeType.rangeEdited) {
          const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
          const usedRange = activeWorksheet.getUsedRange();
          const { [UnitoField.UNITO_ID]: unitoIdData, [UnitoField.LAST_MODIFIED]: lastModifiedData } =
            await getUnitoFields(context, usedRange);
          const modifiedCells = activeWorksheet.getRange(changes.address).load();
          await context.sync();

          // Check if the edit was on unito fields values and block the modification. Work for single cell edit only.
          if ([unitoIdData.index, lastModifiedData.index].includes(modifiedCells.columnIndex)) {
            // Property 'details' exist on single cell rangeEdit only
            // ignore this check for edge-legacy as triggerSource didn't exist back then
            if (changes.details && changes.triggerSource !== undefined) {
              modifiedCells.values = [[changes.details.valueBefore]];
              return;
            }
          }

          // If valid range to edit
          if (modifiedCells.columnIndex > unitoIdData.index && modifiedCells.columnIndex < lastModifiedData.index) {
            await populateUnitoFields(context, modifiedCells);
            return;
          }

          await this.checkUnitoFields(context, activeWorksheet);
          // TODO: If other cell in the Unito fields were modified/deleted show warning that the sync for those records will be broken
        }

        if (
          changes.changeType === Excel.DataChangeType.columnDeleted ||
          changes.changeType === Excel.DataChangeType.columnInserted
        ) {
          // Check unito column status
          const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
          await this.checkUnitoFields(context, activeWorksheet);
        }
      } catch (error) {
        if (error.message.includes("Wait until the previous call is completed")) {
          console.log("The number of queued requests exceeded the limit while the user was in cell edit mode");
        }
        console.error(error);
      }
    });
  };

  handleActiveWorksheetChange = async (changes: Excel.WorksheetDeactivatedEventArgs) => {
    try {
      await Excel.run(async (context: Excel.RequestContext) => {
        if (changes.type === Excel.EventType.worksheetDeactivated) {
          const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
          activeWorksheet.onChanged.add(this.handleOnCellChange);
          activeWorksheet.onDeactivated.add(this.handleActiveWorksheetChange);

          const isConfiguredProperty = await this.getIsConfiguredProperty(context, Properties.IS_UNITO_CONFIGURED);

          if (!isConfiguredProperty) {
            await this.checkUnitoFields(context, activeWorksheet);
          }
        }
      });
    } catch (error) {
      // TODO: Add a logger
      console.error(error);
    }
  };

  render() {
    const { isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return <Loading />;
    }

    // is IE11
    // the first condition is just so that you can run the add-in locally outside of excel
    if (Office.context?.requirements && !Office.context.requirements.isSetSupported("ExcelApi", "1.9")) {
      return (
        <Box p={[tokens.spacing.s5, tokens.spacing.s4]}>
          <Box justifyContent="center" m={[0, 0, tokens.spacing.s4, 0]}>
            <StyledLogo src={require("./../../../assets/unito_wordmark@12x.png")} alt="Unito Logo" title="Unito Logo" />
          </Box>
          <Box m={[0, 0, tokens.spacing.s4, 0]} justifyContent="center">
            <Typography>
              This add-in won't run in your version of Office. Please upgrade to either one-time purchase Office 2021 or
              to a Microsoft 365 account.
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Box p={[tokens.spacing.s5, tokens.spacing.s4]}>
        <Header />
        <Box m={[0, 0, tokens.spacing.s4, 0]} justifyContent="center">
          <Typography>Before you get started...</Typography>
        </Box>
        <StepOne setIsConfigured={this.setIsConfigured} isConfigured={this.state.isConfigured} />
        <StepTwo />
        <StepThree />
      </Box>
    );
  }
}
