import * as uuid from "uuid";

import { CellCoordinate, ColumnData, UnitoField, UnitoFieldsData } from "./types";

export const toBase26 = (columnIndex: number, result = "") => {
  const A1_BASE = 26;
  const A_ASCII_VALUE = 65;

  const lastDigit = columnIndex % A1_BASE;
  // We need to remove one because A is 0 not 1
  const remainderToConvert = Math.floor(columnIndex / A1_BASE) - 1;

  if (columnIndex >= A1_BASE) {
    result = toBase26(remainderToConvert, result);
  }

  return result + String.fromCharCode(A_ASCII_VALUE + lastDigit);
};

export const buildA1NotationAddress = (start: CellCoordinate, end: CellCoordinate) => {
  const startColumn = typeof start.column === "number" ? toBase26(start.column) : start.column;
  const endColumn = typeof end.column === "number" ? toBase26(end.column) : end.column;

  return `${startColumn}${start.row}:${endColumn}${end.row}`;
};

function findCell(value: string, range: Excel.Range): Excel.Range {
  const CELL_PROPERTIES: (keyof Excel.Range)[] = ["isNullObject", "columnIndex"];
  return range.findOrNullObject(value, { completeMatch: true, matchCase: false }).load(CELL_PROPERTIES);
}

function getCellData(cell: Excel.Range): ColumnData {
  if (cell.isNullObject) {
    return {
      isSet: false,
      alphaPosition: null,
      index: null,
    };
  }

  return {
    isSet: true,
    alphaPosition: toBase26(cell.columnIndex),
    index: cell.columnIndex,
  };
}

export async function getUnitoFields(context: Excel.RequestContext, usedRange: Excel.Range): Promise<UnitoFieldsData> {
  const unitoIdCell = findCell(UnitoField.UNITO_ID, usedRange);
  const lastModifiedCell = findCell(UnitoField.LAST_MODIFIED, usedRange);
  await context.sync();

  return {
    [UnitoField.UNITO_ID]: getCellData(unitoIdCell),
    [UnitoField.LAST_MODIFIED]: getCellData(lastModifiedCell),
  };
}

export async function populateUnitoFields(
  context: Excel.RequestContext,
  recordsRange: Excel.Range,
  isOpening: boolean = false,
) {
  const firstRowIsHeader = recordsRange.rowIndex === 0;
  const rangeIsOneRow = recordsRange.rowCount === 1;

  // If only header values were updated, do nothing
  if (firstRowIsHeader && rangeIsOneRow) {
    return;
  }
  try {
    const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();

    const usedRange = activeWorksheet.getUsedRange();
    const { [UnitoField.UNITO_ID]: unitoIdData, [UnitoField.LAST_MODIFIED]: lastModifiedData } = await getUnitoFields(
      context,
      usedRange,
    );

    if (!unitoIdData.isSet || !lastModifiedData.isSet) {
      return;
    }

    const rowsRange = recordsRange.getEntireRow().load("address");
    await context.sync();
    const [, rowsRangeIndexes] = rowsRange.address.split("!");
    let [firstRowIndex, lastRowIndex] = rowsRangeIndexes.split(":").map((rowIndex) => parseInt(rowIndex));

    // If values in the header were updated, ignore that row
    if (firstRowIsHeader) {
      firstRowIndex++;
    }

    const updatedRecordsRangeAddress = buildA1NotationAddress(
      { column: unitoIdData.alphaPosition, row: `${firstRowIndex}` },
      { column: lastModifiedData.alphaPosition, row: `${lastRowIndex}` },
    );

    const unitoIdRangeToUpdateAddress = buildA1NotationAddress(
      { column: unitoIdData.alphaPosition, row: `${firstRowIndex}` },
      { column: unitoIdData.alphaPosition, row: `${lastRowIndex}` },
    );

    const lastModifiedRangeToUpdateAddress = buildA1NotationAddress(
      { column: lastModifiedData.alphaPosition, row: `${firstRowIndex}` },
      { column: lastModifiedData.alphaPosition, row: `${lastRowIndex}` },
    );

    const updatedRecords = activeWorksheet.getRange(updatedRecordsRangeAddress).load("values");
    const unitoIDRangeToUpdate = activeWorksheet.getRange(unitoIdRangeToUpdateAddress).load("values");
    const lastModifiedRangeToUpdate = activeWorksheet.getRange(lastModifiedRangeToUpdateAddress).load(["values"]);
    await context.sync();

    const unitoIdFieldValues = updatedRecords.values.map((row) => {
      // Don't overwrite existing ID
      if (row[unitoIdData.index]) {
        return [row[unitoIdData.index]];
      }
      // Only add an ID if there is values in the row cells
      if (row.some((value) => value)) {
        return [uuid.v4()];
      }
      return [""];
    });

    const lastModifiedFieldValues = updatedRecords.values.map((row) => {
      // If it is the check/populate unito fields on re-opening the add-in,
      // DO NOT overwrite the existing date
      if (isOpening && row[lastModifiedData.index]) {
        return [row[lastModifiedData.index]];
      }
      // Only add/update de date if there is values in the row cells
      if (row.some((value) => value)) {
        return [new Date().toISOString()];
      }
      return [""];
    });

    unitoIDRangeToUpdate.values = unitoIdFieldValues;
    lastModifiedRangeToUpdate.values = lastModifiedFieldValues;

    await context.sync();

    await saveWorkbook(context);
  } catch (error) {
    console.log(`Error: ${error.message}`);
  }
}

export async function saveWorkbook(context: Excel.RequestContext) {
  context.workbook.save();
  await context.sync();
}
