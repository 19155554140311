import React from "react";
import styled from "styled-components";
import { Spinner, SpinnerSize } from "@fluentui/react";

import { Box, tokens } from "./UI";

const FullPage = styled(Box)`
  background: ${tokens.colors.content.secondary.default};
  color: ${tokens.colors.global.primary.light};
  min-height: 100vh;
`;

const StyledLogo = styled.img`
  width: 102px;
`;

export default function Loading() {
  return (
    <FullPage flexDirection="column" justifyContent="center" alignItems="center">
      <Box m={[0, 0, tokens.spacing.s5, 0]}>
        <StyledLogo src={require("./../../../assets/unitologo_96px.png")} alt="Unito Logo" title="Unito Logo" />
      </Box>
      <Spinner size={SpinnerSize.large} label="Loading" />
    </FullPage>
  );
}
